* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

button {
  text-transform: none !important;
}

#root {
  height: 100%;
}

.chip {
  height: auto;
}

/* Typography */
.title {
  padding-bottom: .5rem;
}

/* Sidebar */
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover,
.MuiListItem-root.Mui-selected svg {
  background: none;
  color: #0080FF;
}

.MuiListItemIcon-root {
  min-width: 36px;
}

/* Form */
.MuiFormControl-marginDense {
  margin: 0;
}

/* Button */
.MuiIconButton-root {
  padding: 4px;
}

.MuiButton-root {
  border-radius: 4px;
}

.MuiTableCell-root .MuiIconButton-root {
  background: none;
  color: #526272;
}

.MuiTableCell-root .MuiIconButton-root>p {
  font-weight: bold;
}

.MuiTableCell-root .MuiIconButton-root:hover {
  color: #0080ff;
}

.MuiTableCell-root .MuiIconButton-root:hover>p {
  color: #0080ff;
}

/* Toggle Button */
.MuiToggleButton-sizeSmall {
  padding: 7px 15px;
  font-size: 0.9125rem;
  width: 180px;
  border: 2px solid #dfe6f2 !important;
  margin-right: 10px;
  border-radius: 6px !important;
  color: #454545;
  justify-content: start;
}

.MuiToggleButton-root.Mui-selected:hover,
.MuiToggleButton-root:hover {
  text-decoration: none;
  background-color: #f4f8ff;
}

.MuiToggleButton-root.Mui-selected {
  color: #0080ff;
  background-color: #fff;
  border-color: #0080ff !important;
}

/* Input */
.MuiInputLabel-outlined {
  transform: translate(12px, 10px) scale(1);
}

.MuiOutlinedInput-input {
  padding: 10.5px 14px;
}

.MuiOutlinedInput-inputAdornedStart {
  text-align: right;
}

.MuiOutlinedInput-adornedStart {
  background-color: #fff;
}

/* Colors Text */
.primary {
  color: #0080ff;
}

.success {
  color: #4caf50;
}

.error {
  color: #f44336;
}

.warning {
  color: #ff9800;
}

.secondary {
  color: #898989;
}

.dark {
  color: #454545;
}

/* Font */
.bold {
  font-weight: bold;
}

/* Dialog */
.MuiDialogTitle-root>h2 {
  font-weight: bold;
  color: #454545;
  font-size: 1.5rem;
}

/* Margins */
.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

/* Dropzone */
.MuiDropzoneArea-root {
  min-height: 150px;
}

.MuiDropzoneArea-root:focus {
  border-color: #0080ff;
  outline: none;
}

.MuiDropzoneArea-text {
  font-size: 18px !important;
  color: rgba(0, 0, 0, 0.32);
}

.MuiDropzoneArea-icon {
  color: rgba(0, 0, 0, 0.32);
}

.MuiTypography-subtitle1 {
  font-size: .8rem;
}

.MuiChip-root {
  background-color: #ecf0f5;
  border-color: #ecf0f5;
}

/* Submenu */
.submenu-item {
  color: #898989;
}

.submenu-item:hover {
  color: #424242;
}

/* Alert */
tr .MuiAlert-root {
  padding: 0;
}

tr .MuiAlert-message {
  text-align: center;
  padding: 4px 10px;
  margin: 0 auto;
}

/* Table */
tr:nth-child(even) {
  background-color: #fbfbfb;
}

.MuiTableCell-head {
  font-weight: 700;
}

.MuiTableCell-root {
  padding: 4px 8px;
  word-wrap: break-word;
  font-family: Nunito Sans, sans-serif !important;
  font-size: 14px !important;
  line-height: 1.43;
}

.MuiTableCell-head {
  border-bottom: 2px solid rgba(224, 224, 224, 1);
}

.MuiTablePagination-toolbar {
  min-height: 0;
  padding: 10px 0 0;
}

.table-bordered {
  border: 1px solid rgba(224, 224, 224, 1);
  border-collapse: collapse;
}

.table-bordered>th,
.table-bordered>td {
  border: 1px solid rgba(224, 224, 224, 1);
}

.table-noborder {
  border: 0px solid rgba(224, 224, 224, 1);
  border-collapse: collapse;
}

.table-noborder>th,
.table-noborder>td {
  border: 0px solid rgba(224, 224, 224, 1);
}

.MuiTableCell-paddingNone:last-child>div {
  display: flex;
  justify-content: center;
}

/* Tabs */
.bordered-tab {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/* Timeline */
.MuiTimelineConnector-root {
  background-color: #526272;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.react-datepicker__close-icon::after {
  background-color: rgba(0, 0, 0, 0.54);
  content: '\00d7';
  font-size: 14px;
}

.MuiTooltip-tooltip {
  font-size: 0.8rem;
}

.margin-chip {
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin-typograp {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 0px;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}